import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Body from './Home';
import About from './About';
import Services from './Services';
import Faq from './Faq';
import ContactUs from './ContactUs';
import UploadInput from './UploadInput';
import Upload from './Upload';
import Feedback from './Feedback';
import Activities from './Activities';
import AfterUpload from './AfterUpload';

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={
          <Body />
          } />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/uploadinput" element={<UploadInput />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/afterupload" element={<AfterUpload />} />
          <Route path='/feedback' element={<Feedback />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

