import React, { useState, useEffect } from 'react';
import './Testimonial.css';

function Testimonial() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialItems = [
    {
      content: 'Just wanted to say your people and the service is amazing',
      author: 'Abdi',
      role: 'Client',
    },
    {
      content: 'A big thank you to Gitongo for excellent facilitation of the package delivery',
      author: 'Alex',
      role: 'Client',
    },
    {
      content: 'Great Team and Services',
      author: 'Felix',
      role: 'Client',
    },
  ];

  // Function to automatically advance to the next testimonial
  const advanceTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex < testimonialItems.length - 1 ? prevIndex + 1 : 0));
  };

  useEffect(() => {
    // Set up an interval to automatically advance the testimonial every 5 seconds (5000 milliseconds)
    const interval = setInterval(advanceTestimonial, 2000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='testimonial'>
      <div className='testimonial__header'>
        <h3>Testimonials</h3>
        <h1>What Our Customers Say</h1>
      </div>
      <div className='testimonial__information'>
        {testimonialItems.map((item, index) => (
          <div
            key={index}
            className='testimonial__info-item'
            style={{
              opacity: index === currentIndex ? 1 : 0,
              transform: index === currentIndex ? 'translateX(0%)' : 'translateX(100%)',
            }}
          >
            <p>{item.content}</p>
            <h4>{item.author}</h4>
            <h6>{item.role}</h6>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Testimonial;
