import React from 'react'
import "./Delivery.css"

function Delivery() {
  return (
    <div className='delivery'>
      <div className='head__information'>
        <h3>How To Ship</h3>
        <h1>Delivery Process</h1>
      </div>
      <div className='cards__display'>
            <div className='card__one'>
             <h1>1</h1>
             <h2>Contact you supplier</h2>
             <p>Liaise with your supplier,
                 agree on the prices and items you wish to buy. 
                 Give the supplier our shipping address.</p>
            </div>
            <div className='card__two'>
             <h1>2</h1>
             <h2>We pay on your behalf</h2>
             <p>Send the funds to our FourOneOne Logistics Account, 
                then we remit the payments on your behalf</p>
            </div>
            <div className='card__three'>
             <h1>3</h1>
             <h2>Realtime updates</h2>
             <p>Once the package arrives at our warehouse, 
                we will send you a message, keeping you posted on the tracking Info.</p>
            </div>
      </div>
    </div>
  )
}

export default Delivery
