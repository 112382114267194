import React from 'react'
import FirstImage from './firstPhoto.jpg'
import SecondImage from './secondPhoto.jpg'
import ThirdImage from './thirdPhoto.jpg'
import FourthImage from './fourthPhoto.jpg'
import './Advert.css'

function Advert() {
  return (
    <div className='advert__image'>
           <div className='top__image'>
           <div>
                <img className='first__image' src={FirstImage} alt='firstImage'></img>
            </div>
            <div>
                <img className='second__image' src={SecondImage} alt='secondImage'></img>
            </div>
           </div>
            <div className='bottom__image'>
            <div>
                <img className='third__image' src={ThirdImage} alt='thirdImage'></img>
            </div>
            <div>
                <img className='fourth__image' src={FourthImage} alt='fourthImage'></img>
            </div>
            </div>
    </div>
  )
}

export default Advert
