import React from 'react'
import Header from './Header';
import Navigation from './Navigation';
import './Home.css';
import News from './News';
import UnderNews from './UnderNews';
import Cards from './Cards';
import UnderCard from './UnderCard';
import Delivery from './Delivery';
import Testimonial from './Testimonial';
import AboveFooter from './AboveFooter';
import Footer from './Footer';
import Advert from './Advert';

function Body() {
  return (
    <div className='body'>
      <Header />
      <Navigation />
      <Advert />
      {/* <News /> */}
        {/* <UnderNews />
        <Cards /> */}
      <UnderCard />
      <Delivery />
      <Testimonial />
      <AboveFooter />
      <Footer />
      </div>
  )
}

export default Body
