import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import './Activities.css';
import Navigation from './Navigation';

function Activity() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Fetch the uploaded files from Firestore
    const fetchData = async () => {
      const filesData = [];
      const querySnapshot = await db.collection('uploadedFiles').get();
      querySnapshot.forEach((doc) => {
        filesData.push({ id: doc.id, ...doc.data() });
      });
      setFiles(filesData);
    };

    fetchData();
  }, []);

  return (
    <div className="activity">
      <div className='activity__top'>
        <Navigation />
      </div>
      <div className='activity__information'>
        <h2>OUR DAILY FEEDS</h2>
        {files.length === 0 ? (
          <p className='p__center'>No Activities Yet,Wait to be upload</p>
        ) : (
          <div className="file-list">
            {files.map((file) => (
              <div key={file.id} className="file-item">
                <img src={file.downloadURL} alt="Uploaded" />
                <p>{file.description}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Activity;
