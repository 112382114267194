import React from 'react'
import logoImage from './411logo.jpg'
import './Navigation.css'
import { useState } from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

function Navigation() {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);

  };

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logoImage} alt='logo'></img>
      </div>
      <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <li><a href="/">Home</a></li>
        <li><a href="/about">About</a></li>
        <li><a href="/activities">Activities</a></li>
        <li><a href="/faq">F.A.Q</a></li>
        <li><a href="/services">Services</a></li>
        <li><a href="/uploadinput">Uploads</a></li>
        <li><a href="/contactus">Contact Us</a></li>
        <li><a href="/feedback">Feedback</a></li>
      </ul>
      <div
        className="mobile-menu-button">
          {!isMobileMenuOpen ?  <MenuIcon onClick={toggleMobileMenu}/>:<CloseIcon onClick={toggleMobileMenu}/>}
      </div>
    </nav>

  )
}

export default Navigation
