import React, { useState } from 'react';
import './UploadInput.css';
import Home from './Home'
import Upload from './Upload';
import {useNavigate} from 'react-router-dom';

function UploadInput() {
  const [userInput, setUserInput] = useState('');
  const [isValid, setIsValid] = useState(false);
 // const history = useHistory();
 const navigate = useNavigate();

  // Define the hardcoded key
  const hardcodedKey = 'Gitonga123';
  console.log(`am hardcodekey ${hardcodedKey}`);

  // Event handler to update user input
  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  // Event handler to check if the input matches the key
  const handleSubmit = () => {
    if (userInput === hardcodedKey) {
      setIsValid(true);
      navigate('/upload',{replace: true});
    } else {
      setIsValid(false);
      alert('Invalid key. Please try again.');
    }
  };

  return (
    <div className='input__upload'>
      <div className='input__details'>
        <h1>Enter PassKey to Proceed</h1>
        <input
          type='text'
          value={userInput}
          onChange={handleInputChange}
        ></input>
        <button type='button' onClick={handleSubmit}>
          Submit
        </button>
        {isValid && <p>Key is valid. You can proceed.</p>}
      </div>
    </div>
  );
}

export default UploadInput;

