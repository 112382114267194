import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import './Header.css';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function Header() {
  return (
    <div className='header'>
      <div className='header__left'>
        <div className='location__direction'>
            <LocationOnIcon />
        </div>
        <div className='location__text'>
            <h3>Based at Nextgen mall,Mombasa Road</h3>
        </div>
      </div>
      <div className='header__center'>
        <div className='phone'>
            <PhoneIcon />
        </div>
        <div className='phone_number'>
            <h3>(+254) 712031735/780031735</h3>
        </div>
      </div>
      <div className='header__right'>
        <div className='media__icons'>
            <a href='https://www.facebook.com/search/top?q=fouroneone%20logistics%20ltd.'><FacebookIcon /></a>
            <a href='https://twitter.com/frankkimoshi87'><TwitterIcon /></a>
            <a href='https://www.linkedin.com/in/frankline-kimonye-8969a8126/'><LinkedInIcon /></a>
        </div>
      </div>
    </div>
  )
}

export default Header
