import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Navigation from './Navigation'
import logoImage from './411logo.jpg'
import headOffice from './headoffice.jpg'
import emailImage from './emailimage.png'
import phoneImage from './phoneimage.jpg'
import AboveFooter from './AboveFooter'
import CheckIcon from '@material-ui/icons/Check';
import Header from './Header';
import Footer from './Footer';
import './ContactUs.css'
function ContactUs() {
  return (
    <div className='contact'>
        <div className='contact__top'>
            <Navigation />
              <div className='contact__nav'>
                  <h2>Contact Us</h2>
              </div>
        </div>
        <div className='contact__information'>
            <div className='information__top'>
                <h2>Lets get in Touch</h2>
                <h1>Stay Tuned & Let us Connect</h1>
            </div>
            <div className='information__details'>
                <div className='details__first'>
                    <img src={headOffice} alt='head'></img>
                    <div className='details__headers'>
                        <h1>HEAD OFFICE ADDRESS</h1>
                        <h3>FourOneOne Logistics ltd - Based at Nextgen mall,Mombasa Road . P.O.BOX 14009-00100 NAIROBI - KENYA</h3>
                    </div>
                </div>
                <div className='details__second'>
                    <img src={emailImage} alt='mail'></img>
                    <div className='details__headers2'>
                        <h1>EMAIL ADDRESS</h1>
                        <h3>Email : info@fouroneone.org.ke</h3>
                    </div>
                </div>
                <div className='details__third'>
                    <img src={phoneImage} alt='phone'></img>
                    <div className='details__headers3'>
                        <h1>OFFICE TELEPHONE</h1>
                        <h3>Call (+254) 712031735/780031735</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactUs
