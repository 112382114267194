// Feedback.js
import React, { useState } from 'react';
import Navigation from './Navigation';
import './Feedback.css';
import { db } from './firebase';

function Feedback() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await db.collection('feedback').add(formData);
      alert('Feedback submitted successfully.');
      // Clear the form
      setFormData({
        name: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting feedback.');
    }
  };

  return (
    <div className='feedback'>
      <div className='feedback__top'>
        <Navigation />
        <h1>Your Feedback Matters</h1>
      </div>
      <div className='feedback__input'>
        <form onSubmit={handleSubmit}>
          <div className='name__input'>
            <h2>Full Name:</h2>
            <input
              type='text'
              name='name'
              placeholder='Abdiraahman Abdi'
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className='email__input'>
            <h2>Email:</h2>
            <input
              type='email'
              name='email'
              placeholder='abdi@gmail.com'
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className='comments'>
            <h2>Message:</h2>
            <textarea
              name='message'
              placeholder='Message........'
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <button type='submit'>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default Feedback;
