import React, { useState } from 'react'
import Upload from './Upload';
import Activities from './Activities';

function AfterUpload() {
    // const [fileData, setFileData] = useState({ description: '', fileName: '' });

    // const handleFileUpload = (description, fileName) => {
    //     setFileData({ description, fileName });
    // };
    return (
        <div className='after__upload'>
            {/* <Upload onFileUpload={handleFileUpload} /> */}
            {/* <Activities fileData={fileData} /> */}
        </div>
    )
}

export default AfterUpload
