import React, { useState } from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Navigation from './Navigation'
import logoImage from './411logo.jpg'
import AboveFooter from './AboveFooter'
import CheckIcon from '@material-ui/icons/Check';
import Header from './Header';
import Footer from './Footer';
import "./Faq.css"

function Faq() {
    const faqData = [
        {
            question: "Which Countries Do You Ship From?",
            answer: "We ship your goods by providing tailored end-to-end air freight, sea freight, and courier services from all around Kenya to neigbouring country like Uganda. FourOneOne Cargo has a robust network of partners to serve your global needs.",
        },
        {
            question: "What Are Your Shipping Rates And Charges?",
            answer: "Charges are based on actual weight or volumetric weight of the package. We charge whichever is higher. Shipping by sea is based on Cubic per Meter (CBM). We measure using the formula Length x Width x Height (in meters). ",
        },
        {
            question: "Payments To Suppliers",
            answer: "We assist our customers pay their suppliers all around Kenya. All you need to do is pay at our offices and we shall pay the supplier on your behalf. "
        },
        {
            question: "What Are The Export Charges?",
            answer: "Export charges are paid directly to the customs authorities.  The kind of goods you are exporting will dictate the charges that will be applied. We find the correct tariff applicable based on the description of your goods. Be advised that tariffs are country-specific and can vary from one country to the other.",
        },
        {
            question: "What Time are Your Nairobi Office Open?",
            answer: "Our Nairobi office is open everyday from 9AM to 10PM.",
        },
        {
            question: "Do You Ship From The USA?",
            answer: "Currently we do not have an office in USA, and for this reason we are unable to ship.😔",
        },
        {
            question: "What Time are Your Nairobi Office Open?",
            answer: "Our Nairobi office is open everyday from 9AM to 10PM.",
        }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        if (index === activeIndex) {
            // Clicked the same question, close it
            setActiveIndex(null);
        } else {
            // Clicked a different question, open it
            setActiveIndex(index);
        }
    };

    return (
        <div className='faq'>
            <div className='faq__top'>
                <Navigation />
                <div className='faq__nav'>
                    <h2>Frequently Asked Questions</h2>
                </div>
            </div>
            <div className='faq__body'>
                {faqData.map((faq, index) => (
                    <div className="faq__item" key={index}>
                        <div
                            className={`faq__question ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => toggleAnswer(index)}
                            style={{ color: 'black', fontWeight: 'bolder',fontSize: '20px', borderBottom: '1px solid #b3b6ba', padding: '10px' }}
                        >
                            {/* Use a clickable icon (e.g., a down arrow) */}
                            <span style={{ cursor: 'pointer' }}>
                                {activeIndex === index ? '▲' : '▼'}
                            </span>
                            <span>{faq.question}</span>
                        </div>
                        {activeIndex === index && (
                            <div className="faq__answer" style={{ color: 'gray', fontWeight: 'bolder',fontSize: '16px', borderBottom: '2px solid #b3b6ba', padding: '10px' }}>{faq.answer}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Faq
