// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//import firebase from "firebase"
//import firebase from "firebase";
import firebase from 'firebase'; // Import the main Firebase module
//import 'firebase/storage'; // Import the storage module
//import 'firebase/firestore'; // Import the firestore module
const firebaseConfig = {
    apiKey: "AIzaSyC3xFukx_fpzp49wcXLU265cOcp59-WJuM",
    authDomain: "four11-4ac6b.firebaseapp.com",
    projectId: "four11-4ac6b",
    storageBucket: "four11-4ac6b.appspot.com",
    messagingSenderId: "907185906734",
    appId: "1:907185906734:web:709ef0c6fedd512bd9ebbf"
  };

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();

export { db, storage };

