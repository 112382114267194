import React, { useState } from 'react'
import Navigation from './Navigation'
import './Upload.css'
import {useNavigate} from 'react-router-dom';
//import {db,storage} from "./firebase"
/*import { db, storage } from "./firebase";
import firebase from "firebase"*/
//import {db,storage} from "./firebase"
import { db, storage } from './firebase'; // Correct import path

//import firebase from "firebase"

function Upload() {
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState('');
    const navigate = useNavigate();

    // Function to handle file selection
    const handleFileSelect = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
    };

    // Function to upload the selected file to Firebase Storage
  const handleFileUpload = () => {
    if (selectedFile) {
      const storageRef = storage.ref(); // Use the storage object imported from firebase.js
      const fileRef = storageRef.child(selectedFile.name);

      fileRef.put(selectedFile).then((snapshot) => {
        console.log('File uploaded successfully');
        // You can perform additional actions after successful upload

        // If you want to get the download URL of the uploaded file:
        fileRef.getDownloadURL().then((downloadURL) => {
          console.log('Download URL:', downloadURL);

          // Update the description state
         //setDescription(description);

           // Save the download URL and description to Firestore
           db.collection('uploadedFiles').add({
            downloadURL,
            description,
          });

          setDescription('');
          setSelectedFile(null);

          navigate('/activities',{replace: true});


          //onFileUpload(description, selectedFile.name);
          // You can save this URL to your Firestore or use it as needed
        }).catch((error) => {
          console.error('Error getting download URL:', error);
        });
      }).catch((error) => {
        console.error('Error uploading file: ', error);
      });
    } else {
      console.error('No file selected.');
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
    return (
        <div className='upload'>
            <div className='upload__top'>
                <Navigation />
            </div>
            <div className='upload__information'>
            <div className='file__upload'>
               <div className='upload__description'>
                <h1>Description</h1>
                <textarea type="text" 
                placeholder='What is in your Mind?'
                value={description}
                onChange={handleDescriptionChange}
                ></textarea>
               </div>
                <input
                    type="file"
                    accept="image/*" // Set accepted file types (e.g., images)
                    onChange={handleFileSelect}
                />
                <button onClick={handleFileUpload}>Upload File</button>
            </div>
            </div>
        </div>
    )
}

export default Upload
