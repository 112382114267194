import React from 'react'
import logoImage from './411logo.jpg'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import './AboveFooter.css';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function Header() {
  return (
    <div className='header__above'>
      <div className='above__left'>
        <div className='above__direction'>
            <LocationOnIcon className='icon__location' />
        </div>
        <div className='above__text'>
          <img src={logoImage} alt="logo" />
        </div>
      </div>
      <div className='above__center'>
        <div className='above__phone'>
            <PhoneIcon />
        </div>
        <div className='above__number'>
            <h3>Call (+254) 712031735/780031735 || Email : fouroneoneltd@yahoo.com</h3>
        </div>
      </div>
      <div className='above__right'>
        <div className='above__icons'>
            <a href='https://www.facebook.com/search/top?q=fouroneone%20logistics%20ltd.'><FacebookIcon /></a>
            <a href='https://twitter.com/frankkimoshi87'><TwitterIcon /></a>
            <a href='https://www.linkedin.com/in/frankline-kimonye-8969a8126/'><LinkedInIcon /></a>
        </div>
      </div>
    </div>
  )
}

export default Header
