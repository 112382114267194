import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Navigation from './Navigation'
import logoImage from './411logo.jpg'
import AboveFooter from './AboveFooter'
import "./About.css"
import CheckIcon from '@material-ui/icons/Check';
import Header from './Header';
import Footer from './Footer';

function About() {
  return (
    <div className='about'>
      <div className='about__top'>
        <Navigation />
        <div className='about__nav'>
          <h2>SEA AND LAND</h2>
          <h2>TRANSPORTATION EXPERTS</h2>
        </div>
      </div>
      <div className='about__center'>
        <div className='image__video'>
          <img src={logoImage} alt='image'></img>
          <video controls>
            <source src="/video.mp4" type="video/mp4" />
          </video>
        </div>
        <div className='about__information'>
          <h1>ABOUT US</h1>
          <h3>Your Shipping Partner</h3>
          <p>FourOneOne Logistic is an established land and sea  freight forwarder with over many successful years of trusted and verified expertise.

            With the backing of many experienced and professionally trained staff and the excellent relationship with carriers, customs, terminals, and other local authorities, We will be your best choice on the extension of your business.

            We offer a wide range of land, air and sea freight services to Kenya and Countries, with a primary focus on reliability and communication that our customers can depend upon</p>
          <p>Whether you are shipping B2B or B2C, rest assured your consignments will be handled with the utmost care and attention.

            Choose FourOneOne Logistics and allow our Experts to eliminate the pain and effort of dealing with external carriers, customs clearance, compliance, and any separately required paperwork,
            ensuring high quality throughout the supply chain.</p>
          <div className='information__nav'>
            <a href='/'>
              <div className='icon-container'>
                <CheckIcon fontSize='small' />
              </div>
              Land Freight
            </a>
            <a href='/'>
              <div className='icon-container'>
                <CheckIcon fontSize='small' />
              </div>
              Water Freight
            </a>
            <div className='description'>
              <p className='description__paragraph'>
                As one of the leading freight forwarders, you can always rely on FourOneOne Logistic first class shipping services at very competitive rates to ensure the most efficient connection of your shipments by Air,Land or Sea from all over Kenya and beyond.

                Our rates are reasonable, especially in comparison to airline excess luggage prices!

                Want to keep your freight costs to a minimum?

                Please contact our friendly Contact Center +254 712031735/780031735 to see how we can assist with your logistical requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='about__bottom'>
        <div className='mission__vission'>
          <div className='mission'>
            <h2 className='mission__h2'>Our Mission</h2>
            <p className='mission__p'>At FourOneOne,Our mission is to get job done commited to quality</p>
            <p className='mission__p'>safety efficiency,performance,satisfaction,</p>
            <p className='mission__p'>vigilance solutions to your cargo</p>
          </div>
          <div className='vission'>
          <h2 className='vission__h2'>Our Vission</h2>
            <p className='vission__p'>FourOneOne logistics main goal is to provide you with reliable,flexible and</p>
            <p className='vission__p'>excellent Services at a competitive rates and innovative solution</p>
          </div>
        </div>
        <div className='core__value'>
        <h2 className='core__h2'>Our Core Value</h2>
            <p className='core__p'>FourOneOne Logistic main goal is to provide you with 
             excellent services while making sure that your time and money are well preserved.
             By virtue of our exclusive agreements
             and commitments with leading carriers in both Sea and</p>
            <p className='core__p'>Land, we enjoy special rates on all of our shipments and confirmed space in 
            every booking which we will pass to you to guarantee a speedy and hassle-free delivery.</p>
        </div>
      </div>
      <div className='about__f'>
          <AboveFooter />
          <Footer />
        </div>
    </div>
  )
}

export default About
