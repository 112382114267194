import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Navigation from './Navigation'
import logoImage from './411logo.jpg'
import AboveFooter from './AboveFooter'
import CheckIcon from '@material-ui/icons/Check';
import Header from './Header';
import Footer from './Footer';
import "./Services.css"
function Services() {
  return (
    <div className='services'>
      <div className='service__top'>
        <Navigation />
        <div className='service__nav'>
          <h2>OUR SERVICES</h2>
          <h2>Best & Efficient Logistics Services</h2>
        </div>
      </div>
      <div className='service__information'>
        <div className='service__left'>
        <div className='information__header'>
            <h3>Transports</h3>
            <p>We convey all kinds of cargo across the region to your door step.We also offer door to door
                transportation of all cargo and parcels within Nairobi city and its environs.
                We have capacity to cover end to end logistics of:
                <ul>
                    <li>Containerized cargo transportation</li>
                    <li>Loose cargo transportation</li>
                    <li>Special load transportation i.e low loader</li>
                    <li>Urgent deliveries</li>
                    <li>door to door services</li>
                    <li>Liquid Cargo i.e tanker shipments</li>
                </ul> 
            </p>
        </div>
        <div className='information__header'>
            <h3>Commission Agents</h3>
            <p>We facilitate in delivery of goods or shipments through exclusive
                agreements,commitments and Well established partnerships we have leading
                carriers and different industry prayers,we facilitate delivery of your
                goods efficiently and timely.
            </p>
        </div>
        <div className='information__header'>
            <h3>Air & Sea Shipping Services</h3>
            <p>We provide freight movements of your cargo,custom clearance,
                tariff classification, formalities & documentation required
                for your imports and exports.
            </p>
        </div>
        </div>
        <div className='service__right'>
        <div className='information__header'>
            <h3>Customs & Clearing Services</h3>
            <p>We do import and export customs evaluation,duty and tax
                assessments,classification of goods and permissions of
                controlled goods and Clearing and forwarding goods to
                your door step.
            </p>
        </div>
        <div className='information__header'>
            <h3>Container Sales</h3>
            <p>We sell used40 & 20FT containers and
                we do container fabrication and conversion
                into any design
            </p>
            <p>EMPTY CONTAINER STORAGE i.e DEPOT</p>
        </div>
        <div className='information__header'>
            <h3>Cargo Consolidation</h3>
            <p>We offer this service by consolidating various small shipments,four one one logistics
                provides trans-border road transit services within eastern Africa to suit your unique
                requirements.Through exclusive agreements,commitments and well established partnerships
                with vast range of proven long haul and locals for transportation.Your goods are
                loaded with other goods and you only pay for the volume of goods that you ship or transport.
            </p>
        </div>
        </div>
      </div>
      <div className='service__f'>
          <AboveFooter />
          <Footer />
        </div>
    </div>
  )
}

export default Services
