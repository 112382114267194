import React from 'react'
import "./UnderCard.css"

function UnderCard() {
  return (
    <div className='undercard'>
      <div className='first__text'>
        <h2>
           We Are a Global Shipping Company
        </h2>
      </div>
      <div className='second__text'>
        <h3>
          Strategically Located In 9+ Countries
        </h3>
      </div>
      <div className='third__card'>
        <p>We have extensive and well-established partnerships with different industry players. This enables us to offer better and most competitive rates. 
            We deliver using the shortest possible transit time with optimum routings that are cost efficient.</p>
      </div>
    </div>
  )
}

export default UnderCard
